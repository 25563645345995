
.topIcons{
    position: relative;
    border-right: 0.5px solid #E7E7E7;
   &:hover{
    pointer-events: auto;
    cursor: pointer;
    background-color: rgba(24, 25, 29, 0.1);
    /* color: white; */
    -ms-transform: scale(1.02); /* IE 9 */
      -webkit-transform: scale(1.02); /* Safari 3-8 */
      transform: scale(1.02); 
      transition: 0.3s all ease-in-out;
      opacity: 1 !important;
   }
    .tooltip{
      visibility: hidden;
    }
   
  }

  .topIcons1{
    position: relative;
    border-left: 0.5px solid #E7E7E7;
   &:hover{
    pointer-events: auto;
    cursor: pointer;
    background-color: rgba(24, 25, 29, 0.1);
    /* color: white; */
    -ms-transform: scale(1.02); /* IE 9 */
      -webkit-transform: scale(1.02); /* Safari 3-8 */
      transform: scale(1.02); 
      transition: 0.3s all ease-in-out;
      opacity: 1 !important;
   }
    .tooltip{
      visibility: hidden;
    }
   
  }

  .grid{
    z-index: inherit;
    display: grid;
    grid-template-columns: 2fr 5fr 1fr 1fr 1fr;
    height:auto;
    padding:0px;
    grid-gap: 0ch;
    border: 0.5px solid #E7E7E7;
    & > div { 
     
      display: flex;
      align-items: center;
        justify-content: center;
        height: 67px;
      //  border-right: 0.5px solid #E7E7E7;
      &:nth-child(1){
        border-right: 0.5px solid #E7E7E7;
      }
       &:nth-child(5){
        background-color: #277EFB;
        color: white;
        border-right: none;
        height: 100.79%;
        width: 100.3%;
        &:hover{
          background-color: #277EFB !important;
          color: white !important;
        }
      }
      &:nth-child(n+3){
       
        &:hover{
          pointer-events: auto;
          cursor: pointer;
          // background-color: rgba(24, 25, 29, 0.1);
          /* color: white; */
          -ms-transform: scale(1.01); /* IE 9 */
            -webkit-transform: scale(1.01); /* Safari 3-8 */
            transform: scale(1.01); 
            transition: 0.2s all ease-in-out;
            opacity: 1 !important;
            color: #182542;
        }
      }
    }
    
    
  }

  .dropDown{
    z-index: 99999;
    position: fixed;
    top: 80px;
    right: 0;
    margin-right: 50px;
    // background-color: red;
    width: 310px;
    height: 340px;
    border-radius: 15px;
    border: 1px solid #E5E5E5;
    background: #FFF;
    // padding-top: 14px;
    padding: 14px 9px;
    div{
      display: flex;
      align-items: center;
      // justify-content: center;
      padding-left: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      height: 50px;
      &:hover{
        background-color: rgba(39, 126, 251, 0.05);
        cursor: pointer;
        span{
          font-weight: 500;
        }
      }
      
    }
  }

  .gridNoBorder{
    z-index: inherit;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height:auto;
    padding:0px;
    grid-gap: 0ch;
    & > div { 
      display: flex;
      align-items: center;
        justify-content: center;
        height: 67px;
       border: 0.5px solid rgba(229, 229, 229, 0.04);  
      &:nth-child(9){
        // background-color: #656565 !important;
        background-color: #182542;
        color: white;
        opacity: 0.4;
      }
    }
    
  }
  
  .gridTwo{
    z-index: inherit;
    display: grid;
    grid-template-columns: 1.1fr 8.8fr 1.1fr;
    height:auto;
    padding:0px;
    grid-gap: 0ch;
    border-right: 0.5px solid #E7E7E7;
    & > div {
      display: flex;
      align-items: center;
        justify-content: center;
        height: 40px;
     }  
  }


  
  
  .gridThree{
    z-index: inherit;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height:auto;
    padding:0px;
    grid-gap: 0ch;
    & > div { 
      display: flex;
      align-items: center;
        justify-content: center;
        height: 67px;
       border: 0.5px solid rgba(229, 229, 229, 0.04);  
      &:nth-child(8){
        // background-color: #656565 !important;
        background-color: #182542;
        color: white;
        opacity: 0.4;
      }
    }
    
  }

  .searchBox {
    width: 90%;
    height: 45px;
    background: #f2f2f2;
    // border: 0.5px solid #E7E7E7;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
  
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    
    color:  #182542;
  }
  
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.002em;
  
  color: #182542;
  
  opacity: 0.25;
  }

  .selectednav{
    font-weight: 700;
    // border-bottom: 2px solid #182542;
    border-width: 3px;
    color: #182542;
  }
  .nonselectednav{
    // font-weight: 700;
    // border-bottom: 2px solid #182542;
    border-width: 3px;
    color: #182542;
  }

  .mobileMenu{
    display: none;
  }
.gridMobile{
  display: none;
}
.gridMobile1{
  display: none;
}

  @media only screen and (max-width: 700px) {

      
  .gridTwo{
    z-index: inherit;
    display: grid;
    grid-template-columns: 3fr 7fr 3fr;
    height:auto;
    padding:0px;
    grid-gap: 0ch;
    border-right: 0.5px solid #E7E7E7;
    & > div {
      display: flex;
      align-items: center;
        justify-content: center;
        height: 40px;
     }  
  }
   
    .grid{
     display: none;
    }
    .gridMobile1{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0.5px solid #E7E7E7;
      border-width: 0.5px 0px 0.5px 0px;
      padding:30px;
      height: 80px;
    }
    .gridMobile{
      padding:30px;
      height: 80px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      border: 0.5px solid #E7E7E7;
      border-width: 0.5px 0px 0.5px 0px;
   
    }

    .mobileMenu{
      display: block;
      padding-right: 100px;
    }
    .menuLogo{
      margin-left: -20%;
      width: 20px;
    }
    .mobileDraw{
      z-index: 999999;
      position: fixed;
      background-color: #182542;
      height: 100%;
      width: 70%;
      transition: all 0.3s ease-in-out;
      padding: 20px;
      padding-bottom: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .mobileBrand{
      color: #FFF;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      // background-color: red;
      display: flex;
      justify-content:flex-start;
      align-items: center;
      padding-bottom: 30px;
      
    }
    .mobileEco{
      color: #FFF;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      justify-content:space-between;
      align-items: center;
    }
    .expandEcoMenu{
      display: flex;
      justify-content:flex-start;
      align-items: center;
      padding-left: 10px;
      padding-top: 25px;
      color: #FFF;
      // font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .mobileLaunchBtn{
      // margin-top: -180px;
      border-radius: 10px;
      background: #277EFB;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }