@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
.App {
  text-align: center;
}
body{
  overflow: hidden;
}
body #root * {
  box-sizing: border-box;
  font-family: Montserrat;
  outline: none;
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.categoryStyle{
  display: flex;
  align-items: center;
  
  height: 63px;
  padding: 20px;
  & > div{
    padding-left: 15px;
  }
  &:hover{
    background: rgba(228, 232, 235, 0.6);
    border-radius: 10px 0px 0px 10px;
    cursor: pointer;
  }
}
.selectedCategoryStyle{
  display: flex;
  align-items: center;
  height: 63px;
  padding: 20px;
  border-radius: 10px 0px 0px 10px;
 
// background: #ffffff;
// box-shadow:  11px 11px 50px #ebebeb,
//              -11px -11px 50px #ffffff;
background: #ffffff;
box-shadow:  -9px 9px 41px #e3e3e3,
             9px -9px 41px #ffffff;
  & > div{
    padding-left: 15px;
  }
  background: white
}

.individualApps{
  display: flex;
  height: 150px;

  // width: 70%;
  // align-items: center;
  // padding-bottom: 95px;

  &:hover{
    transform: scale(1.05);
    transition: all 300ms ease-in-out;
    cursor: pointer;
    // transform: scale(1.1) 300ms ease-in-out;

  }
 .appIcon{
  width: 85.71px;
  height: 85.71px;
  background: white;
  // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding:20px;
  border-radius: 10px;

box-shadow:  -9px 9px 41px #e3e3e3,
             9px -9px 41px #ffffff;
 }

 .fakeAppIcon{
  // width: 85.71px;
  // height: 85.71px;
  // background: gray;
  background-image: url("./Assets//images/loading.gif");
  // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  // border-radius: 10px;
  // padding:20px;
  // border-radius: 10px;
  // box-shadow:  -9px 9px 41px #e3e3e3,
  //            9px -9px 41px #ffffff;
 }

 .title{
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #16171A;
 }
 .subtitle{
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  padding-right: 50px;
  
  color: rgba(24, 36, 67, 0.47);
 }

 .actionButtons{
  width: 72.99px;
  height: 23.3px;
  background: #F1F1F1;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.47);
  font-size: 10px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  &:hover{
    background-color: #287EFB;
    color: white;
    cursor: pointer;
    transition: all 250ms ease-in-out;
  }
 }
}

.overlayClose{
  background-color: rgba(0,0,0,.75);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden!important;
  position: fixed!important;
  right: 0;
  top: 0;
  // z-index: 11;
}

.gridContent{
  display: grid;
  grid-template-columns: 2.001fr 7.99fr;
  height:100vh;
  padding:0px;
  grid-gap: 0ch;   
  transition: all 200ms ease-in-out;
}
.smallGridContent{
  display: grid;
  grid-template-columns: 0.8fr 10fr;
  height:100vh;
  padding:0px;
  grid-gap: 0ch; 
  transition: all 200ms ease-in-out; 
}
.categorySection{
  // border-right: 0.5px solid #EBEBEB;
  padding-left: 40px;
  padding-top: 40px;
  overflow-y: scroll;
  background-color: whitesmoke;
  &::-webkit-scrollbar {
    display: none;
  }
}
.appGridSection{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 180px);
  padding: 54px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  
}
 .mobileAppGridSection{
  display: none;
}



.collapseButton{
  
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 0.5px solid #E7E7E7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 19.35%;
  top: 96px;
  background: white;
  cursor: pointer;
  z-index:  1;
  transition: all 200ms ease-in-out;
}
.collapseButton1{
  
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 0.5px solid #E7E7E7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 6.6%;
  top: 117px;
  background: white;
  cursor: pointer;
  z-index:  1;
  transition: all 200ms ease-in-out;
}


.fakeImage{
  height: 40px;
  width: 40px;
  background: lightgray;
}


@media only screen and (max-width: 600px) {
  .gridContent{
    display: grid;
    grid-template-columns: 1fr;
    height:100vh;
    padding:0px;
    grid-gap: 0ch;   
  }
  .categorySection{
    display: none;
  }
  .appGridSection{
    display: none;
  }
  .mobileAppGridSection{
    display: block;
    padding: 30px;
    overflow-y: scroll;
    padding-bottom: 20vh;
    &::-webkit-scrollbar {
      display: none;
    }
    .categoryTitle{
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #16171A;
    }
    .categorySubTitle{
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: #16171A;
      padding-top: 4px;
    }
    .horizonalScrollDiv{
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .mobileAppIcon{
      background: #FFFFFF;
      border: 0.5px solid #E5E5E5;
      border-radius: 10px;
      height: 108px;
      width: 108px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
    }
    .appTitle{
      font-weight: 800;
      font-size: 13px;
      line-height: 16px;
      color: #16171A;
      margin-right: 20px;
      padding-top: 10px;
    }
    .appSubTitle{
      font-weight: 500;
      // font-size: clamp(1rem, 1vh, 2rem);
      font-size: 7px;
      line-height: 9px;
      color: #16171A;
      margin-right: 20px;
      padding-top: 7px;
      white-space: nowrap;
      width: 100px;
    }
  }


  .collapseButton1{
    display: none;
  }
  .collapseButton {
    display: none;
  }

}